<template>
    <div>
        <HeroContentDetail v-if="data" :data="data" heroType="journey">
            <ul v-if="isAuthenticated">
                <!-- <li v-if="subscriptionInfo">
					<button
						class="button"
						:data-txt="'Buy journey for ' + subscriptionInfo.price"
						@click="payForJourney()"
					>
						Buy journey for {{ subscriptionInfo.price }}
					</button>
				</li> -->
                <!-- <li>
					<button
						class="button white-btn"
						data-txt="Subscribe to Delight"
					>
						Subscribe to Delight
					</button>
				</li> -->
            </ul>
            <ul v-else-if="!isAuthenticated">
                <li>
                    <button @click="loginPopup" class="button" data-txt="Login">
                        Login
                    </button>
                </li>
            </ul>
        </HeroContentDetail>

        <section class="vid-single-meta regular">
            <div class="container">
                <div v-if="data" class="column-wrapper half-half">
                    <div class="column desc"
                        v-if="data.templateContentFields && data.templateContentFields.contentFirst">
                        <h2 class="column-title">Journey info</h2>
                        <div v-html="data.templateContentFields.contentFirst"></div>

                        <a v-if="data.fields.url && data.fields.url !== ''" target="_blank" class="button extra-btn"
                            :href="data.fields.url">{{ data.fields.label }}</a>
                    </div>

                    <div class="column meta-wrapper">
                        <div class="inner-col" v-if="data.fields">
                            <ul class="labels">
                                <li v-if="data.fields.skilcategories &&
            data.fields.skilcategories.length > 0
            ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.skilcategories"
                                        :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="skill" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>
                                    </div>
                                </li>

                                <!-- <li class="duration"
                                    v-if="mediaDetailsObj && mediaDetailsObj.msDuration && mediaDetailsObj.msDuration !== ''">
                                    <div class="item-wrapper">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/duration-blue.svg" class="duration-icon" />
                                        </div>
                                        <span class="label-wrapper">{{ formatToClockDuration(mediaDetailsObj.msDuration)
                                        }}</span>
                                    </div>
                                </li> -->

                                <li v-if="data.fields.level &&
            data.fields.level.length > 0
            ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.level" :key="item.value">
                                        <CatAsIcon :catValue="getEnumVal(item.value)" type="level" />

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }}
                                        </span>

                                    </div>
                                </li>

                                <li v-if="data.fields.nrofvideos &&
            data.fields.nrofvideos.length > 0
            ">
                                    <div class="item-wrapper" v-for="(item) in data.fields.nrofvideos"
                                        :key="item.value">
                                        <div class="icon-wrapper">
                                            <img src="@/assets/svgs/play.svg" class="play-icon" />
                                        </div>

                                        <span class="label-wrapper">
                                            {{ getEnumVal(item.value) }} classes
                                        </span>

                                    </div>
                                </li>

                                <!-- <li v-if="data.freeVideo">
                                <div class="item-wrapper free">Free</div>
                            </li> -->
                            </ul>
                        </div>
                        <div class="inner-col trainer" v-if="trainerData && trainerData !== ''">
                            <router-link :to="'/coaches-trainers/' + trainerData.slug">
                                <div class="left-side"
                                    v-if="trainerData.templateContentMedia && trainerData.templateContentMedia.overviewImage && trainerData.templateContentMedia.overviewImage.url">
                                    <div class="bg-wrapper" v-bind:style="{
            'background-image':
                'url(' + trainerData.templateContentMedia.overviewImage.url + ')',
        }"></div>
                                </div>
                                <div class="right-side"
                                    v-if="trainerData.templateContentFields && trainerData.templateContentFields.displayTitle">

                                    <div class="label">Trainer / coach</div>
                                    <div class="value">{{ trainerData.templateContentFields.displayTitle }}</div>

                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="playlists-wrapper section video-collection"
            v-if="getRelatedPlaylistIds && getRelatedPlaylistIds.length > 0">
            <PlayList v-for="playListId in getRelatedPlaylistIds" :key="playListId" :playlistId="playListId"
                overviewItemType="classes" @playVideoInPopup="playMediaInPopup()">
                <template v-slot:header>
                    &nbsp;
                </template>
            </PlayList>
        </section>

        <section class="playlists-wrapper section video-collection"
            v-if="data && data.relatedContents && data.relatedContents.length > 0">
            <PlayList :relatedContents="data.relatedContents" playlistType="overview" overviewItemType="classes"
                @playVideoInPopup="playMediaInPopup()">
                <template v-slot:header>
                    &nbsp;
                </template>
            </PlayList>
        </section>
    </div>
</template>

<script>
import HeroContentDetail from "@/components/HeroContentDetail";

import PlayList from "@/components/PlayList";

import CatAsIcon from "@/components/CatAsIcon";


import {
    fetchTemplateContentDetailsBySlug,
    fetchPricePlanById,
    checkoutSubscription,
    fetchTemplateContentList
} from "@streampac.io/chef_sp_1";

import { current_enviroment } from "@/constants/endpoints";

export default {
    name: "home",
    inject: ["isAuthenticated"],
    data() {
        return {
            paging: {
                pageNumber: 1,
                pageSize: 6,
            },
            subscriptionInfo: null,
            data: null,
            getRelatedPlaylistIds: [],
            getRelatedVideoItems: [],
            trainerData: null,
            linkedTrainer: null,
            displayNewLabel: false,
        };
    },
    components: {
        // ClassOverviewItem,
        CatAsIcon,
        HeroContentDetail,
        PlayList,
    },
    created() {
        this.getPageContent();
    },
    methods: {
        getPageContent() {
            if (this.$route.params.slug !== "") {
                fetchTemplateContentDetailsBySlug(
                    this.isAuthenticated,
                    this.$route.params.slug,
                    "?flattenFields=true"
                ).then((response) => {
                    this.data = response;

                    //Fetch type and trainer
                    this.fetchTypeAndTrainer();

                    if (
                        response.relatedContents &&
                        response.relatedContents !== ""
                    ) {
                        let playlistIds = [];
                        let singleVideoItems = [];

                        response.relatedContentsExpanded.forEach((related) => {
                            if (related.entityType == "Playlist") {
                                playlistIds.push(related.contentId);
                            }

                            if (related.entityType == "VideoContent") {
                                singleVideoItems.push(related.videoContent);
                            }
                        });

                        // //Regular
                        // response.relatedContents.forEach((related) => {
                        //     if (related.entityType == "VideoContent") {
                        //         playlistIds.push(related.contentId);
                        //     }
                        // });

                        // console.log(response);

                        this.getRelatedPlaylistIds = playlistIds;
                        this.getRelatedVideoItems = singleVideoItems;
                    }

                    if (response.pricingPlans && response.pricingPlans !== "") {
                        fetchPricePlanById(
                            this.isAuthenticated,
                            response.pricingPlans
                        ).then((response) => {
                            this.subscriptionInfo = response;
                        });
                    }

                    // if (
                    // 	response.templateContentFields.mediaFirstImage &&
                    // 	response.templateContentFields.mediaFirstImage !== ""
                    // ) {
                    // 	this.resolveCmsMedia(
                    // 		response.templateContentFields.mediaFirstImage,
                    // 		"mediaFirstImage"
                    // 	);
                    // }

                    // if (
                    // 	response.templateContentFields.mediaSecondImage &&
                    // 	response.templateContentFields.mediaSecondImage !== ""
                    // ) {
                    // 	this.resolveCmsMedia(
                    // 		response.templateContentFields.mediaSecondImage,
                    // 		"mediaSecondImage"
                    // 	);
                    // }
                });
            }
        },
        stripHtml(val) {
            const strippedString = val.replace(/(<([^>]+)>)/gi, "");

            return strippedString;
        },
        // resolveCmsMedia(val, name) {
        // 	if (val !== "") {
        // 		fetchCmsMedia(this.isAuthenticated, val).then((response) => {
        // 			this[name] = response;
        // 		});
        // 	}
        // },
        groupBy(array, key) {
            // Return the end result
            return array.reduce((result, currentValue) => {
                // If an array already present for key, push it to the array. Else create an array and push the object
                (result[currentValue[key]] =
                    result[currentValue[key]] || []).push(currentValue);
                // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                return result;
            }, {}); // empty object is the initial value for result object
        },
        groupByList(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        hasHistory() {
            return window.history.length > 2;
        },
        loginPopup() {
            this.$store.commit("toggleLoginPopup");
        },
        payForJourney() {
            let subId = this.subscriptionInfo.stripePriceId;

            if (
                current_enviroment === "localhost" ||
                current_enviroment === "test"
            ) {
                subId = this.subscriptionInfo.stripePriceTestId;
            }

            let postObj = {
                subscriptionPriceId: subId,
            };

            checkoutSubscription(this.isAuthenticated, postObj).then(
                (response) => {
                    if (response.succes) {
                        window.location.replace(response.redirectUrl);
                    }
                }
            );
        },
        fetchTypeAndTrainer() {
            if (this.data && this.data.fields && this.data.fields.videotype && this.data.fields.videotype.length > 0) {
                this.mainVideoType = this.getEnumVal(this.data.fields.videotype[0].value);
            }

            if (this.data && this.data.fields && this.data.fields.coachestrainers && this.data.fields.coachestrainers.length > 0) {
                this.linkedTrainer = this.data.fields.coachestrainers[0].value;

                const fetchDataParams = {
                    filter: {
                        searchTerm: this.linkedTrainer,
                    },
                    paging: {
                        pageNumber: 1,
                        pageSize: 10,
                    },
                    flattenFields: true,
                };

                const getContentTypes = this.$store.state.getContentTypes;


                if (getContentTypes) {
                    const chosenType = getContentTypes.trainersAndCoaches;

                    fetchTemplateContentList(
                        this.isAuthenticated,
                        chosenType,
                        fetchDataParams,
                        "?flattenFields=true"
                    ).then((response) => {
                        this.trainerData = response[0];
                    });

                }
            }
        },
        playMediaInPopup() {
            console.log('play media in popup');
        }
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
