let get_enviroment;

switch (window.location.hostname) {
	case 'localhost':
		get_enviroment = 'localhost';
		break;
	case 'lead.test.streampac.io':
		get_enviroment = 'test';
		break;
	default:
		get_enviroment = 'prod';
}

export const current_enviroment = get_enviroment;

export const api_url = process.env.VUE_APP_API_URL;

//Admin
//export const user_register_batch = `${api_url}User/registerbatch`;
export const admin_content_sync = `${api_url}Content/SyncContent?customerId=5`;
export const admin_media_sync = `${api_url}Media/SyncMedia?customerId=5`;
